<template>
   <div>
      <div class="row">
         <div class="col-xxl-12" v-if="loading">
            <Skeleton1 />
         </div>
         <template v-else-if="certificate_url !== ''">
            <div class="col-xxl-4 align-content-stretch">
               <iFrameViewer :title="$t('_INTERN.ACTION.GENERAL.CERTIFICATE_URL')" :src="certificate_url" />
            </div>
         </template>
         <span
            v-else
            v-b-tooltip.hover
            title="Document indisponible, contactez votre interlocuteur."
            class="font-weight-bolder font-size-lg text-info"
         >
            <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
         </span>
      </div>
   </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import iFrameViewer from '@/view/content/iFrameViewer';
import Skeleton1 from '@/view/content/skeleton/Skeleton1';
import { GET_SOURCE_EXT } from '@/core/services/store/api/tableSource';
import { GET_CERTIFICATE } from '@/core/services/store/api-orion/intern';

export default {
   data: () => ({
      loading: true,
      certificate_url: ''
   }),
   async mounted() {
      this.resetError;
      this.getCertificate();
      this.setPageTitle();
      setTimeout(() => {
         this.loading = false;
      }, 200);
   },
   methods: {
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, `${this.$t('_INTERN.ACTION.GENERAL.DOWNLOAD_TITLE')}`);
      },
      async getCertificate() {
         const params = {
            tablename: 'users',
            tableid: this.currentUser.id
         };
         await this.$store.dispatch(GET_SOURCE_EXT, { params }).then(async (result) => {
            const params = {
               actionId: this.$route.params.id,
               societeId: this.$route.params.societe,
               stagiaireId: result.extid
            };
            await this.$store.dispatch(GET_CERTIFICATE, { params }).then((result) => {
               if (result) {
                  this.certificate_url = result;
               }
            });
         });
      }
   },
   components: {
      iFrameViewer,
      Skeleton1
   },
   computed: {
      ...mapGetters({
         currentUser: 'currentUser',
         certificate: 'getCertificate'
      })
   }
};
</script>
