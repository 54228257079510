<template>
   <div class="card card-custom gutter-b" :class="stretch && 'card-stretch'">
      <div class="card-header border-0 pt-5 pb-5">
         <h3 class="card-title align-items-start justify-content-center flex-column">
            <a v-if="src" v-b-tooltip.hover="$t('GENERAL.OPEN_FILE')" :href="src" target="_blank">
               <span class="card-label font-weight-bolder text-dark text-hover-primary">
                  {{ title }} <i class="fas fa-external-link-alt p-0 text-dark ml-1"></i></span
            ></a>
            <span v-else class="card-label font-weight-bolder"> {{ title }}</span>
            <span class="text-muted mt-3 font-weight-bold font-size-sm" v-if="subTitle"> {{ subTitle }}</span>
         </h3>
      </div>
      <div class="card-body pt-0">
         <iframe v-if="src" class="w-100" height="450" :src="src"> </iframe>
         <div class="alert alert-info" v-else><i class="fas fa-info-circle text-white"></i> {{ $t('GENERAL.NO_PDF_AVAILABLE') }}</div>
      </div>
      <!-- <div class="card-footer p-3" v-if="src">
            <div class="form-group mb-0 row">
                <div class="col-xxl-12 col-12 text-center">
                    <a v-b-tooltip.hover="$t('GENERAL.OPEN_FILE')" :href="src" target="_blank" class="btn btn-primary btn-sm"
                        ><i class="fas fa-external-link-alt p-0"></i
                    ></a>
                </div>
            </div>
        </div> -->
   </div>
</template>

<script>
export default {
   props: ['src', 'title', 'subTitle', 'stretch']
};
</script>
